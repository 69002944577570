<template>
  <div>
    <b-row>
      <b-col
        v-for="apex in apexList"
        :key="apex.id"
        cols="4"
      >
        <b-card
          no-body
          :title="apex.account.name"
        >
          <b-card-header>
            <b-card-title>{{ apex.account.name }}</b-card-title>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                :to="{ name: 'datadomain-apex-detail.general', params: { id: apex.id } }"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  class="mr-50"
                />
                <span
                  class="align-middle"
                >
                  Open
                </span>
              </b-button>
            </b-button-group>
          </b-card-header>
          <b-card-body>
            <ul>
              <li
                v-for="mtree in apex.details.config.mtrees"
                :key="mtree.name"
              >
                <b-link
                  :to="{ name: 'datadomain-apex-detail.mtrees', params: { id: apex.id }, query: { mtree: mtree.name} }"
                >{{ mtree.name }}</b-link>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BButtonGroup, BButton, BLink,
} from 'bootstrap-vue'
import DataDomainService from '@/service/datadomain.service'

export default {
  components: {
    BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BButtonGroup, BButton, BLink,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      apexList: [],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      DataDomainService.getApexList(this.asup.id, { disableTenantFilter: true })
        .then(result => {
          this.apexList = result.items
        })
    },
  },
}
</script>
